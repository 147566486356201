<template>
  <div>
    <b-button
      :disabled="!$can('export', resource) || loading"
      :variant="!$can('export', resource) ? 'secondary' : 'success'"
      @click="showExportModal()"
    >
      {{ $t("button.exportExcel") }}
    </b-button>
    <b-modal
      ref="modal"
      size="lg"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      hide-footer
      v-model="exportModelVisible"
      @ok="exportExcel"
    >
      <h4 class="text-center title">
        {{ $t("general.exportColumnDescription") }}
      </h4>
      <hr class="mb-2" />
      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(field, index) in columns"
          :key="`column-${index}`"
        >
          <b-form-group>
            <b-form-checkbox v-model="field.isShow" class="text-left">
              {{ $t(field.label) }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mb-2" />

      <b-row class="mb-1">
        <b-col md="4" class="pb-1 pb-md-0 pr-md-1">
          <b-button variant="outline-danger" block @click="resetColumn()">
            {{ $t("button.clear") }}
          </b-button>
        </b-col>
        <b-col md="4" class="pb-1 pb-md-0 px-md-1">
          <b-button variant="outline-primary" block @click="checkedAllColumn()">
            {{ $t("button.selectAll") }}
          </b-button>
        </b-col>
        <b-col md="4" class="pl-md-1">
          <b-button variant="success" block @click="exportExcel()">
            {{ $t("button.export") }}
          </b-button>
        </b-col>
      </b-row>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.export") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import FileSaver from "file-saver";
import { mapExportField } from "@/libs/helper";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    query: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    searchFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    extraQueries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: "File",
    },
    resource: {
      type: String,
      default: "",
    },
    repository: {},
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      exportModelVisible: false,
      columns: [],
    };
  },
  methods: {
    checkedDefaultColumn() {
      let columns = [];
      this.fields.forEach((element) => {
        columns.push({
          ...element,
          isShow: element.secondary ? false : true,
        });
      });
      this.columns = [...columns];
      this.key++;
    },
    checkedAllColumn() {
      let columns = [];
      this.fields.forEach((element) => {
        columns.push({
          ...element,
          isShow: true,
        });
      });
      this.columns = [...columns];
      this.key++;
    },
    resetColumn() {
      let columns = [];
      this.fields.forEach((element) => {
        columns.push({
          ...element,
          isShow: element.hideToggle,
        });
      });
      this.columns = [...columns];
    },
    showExportModal() {
      if (!this.$can("export", this.resource)) {
        return;
      }
      this.checkedDefaultColumn();
      this.exportModelVisible = true;
    },
    exportExcel() {
      if (this.repository) {
        this.exportModelVisible = false;
        this.$showLoading();
        let visibleColumns = [];
        this.columns.forEach((element) => {
          if (element.isShow) {
            visibleColumns.push(element.key);
          }
        });

        let query = {
          ...this.query,
          searchFields: this.searchFields,
          fields: mapExportField(this.fields, visibleColumns),
          query: this.extraQueries,
        };

        if (this.selected.length > 0) {
          query = {
            query: [
              {
                searchby: "id",
                searchoperator: "in",
                search: this.selected,
              },
            ],
            fields: mapExportField(this.fields, visibleColumns),
          };
        }

        this.repository
          .export(query, this.handleDownloadProgress)
          .then((response) => {
            this.loading = false;
            const fileName = `${this.fileName} - ${moment(
              this.query.dateFrom
            ).format("LL")} - ${moment(this.query.dateTo).format("LL")}.xlsx`;
            FileSaver.saveAs(response.data, fileName);
          })
          .catch(() => {
            this.$hideLoading();
          })
          .then(() => {
            this.$hideLoading();
          });
      }
    },
    handleDownloadProgress(progressEvent) {
      const total = progressEvent?.total || 0;
      const current = progressEvent?.loaded || 0;
      this.$showLoading(current, total, true);
    },
  },
};
</script>