<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
      :default-visible="true"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #dateFrom>
          <div v-if="params.period != 'other'"></div>
        </template>
        <template #dateTo>
          <div v-if="params.period != 'other'"></div>
        </template>
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right">
            <export-excel-button
              :resource="resource"
              :query="query"
              :params="params"
              :loading="loading"
              :repository="ReportRepository"
              :fields="fields"
              :search-fields="searchFields"
              :file-name="$t('breadcrumb.activeOutletReport')"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
        >
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import ExportExcelButton from "@/components/ExportExcelButton";
import moment from "moment";

const ReportRepository = Repository.get("activeOutletReport");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    ExportExcelButton,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        period: this.$route.query.period || [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD"),
        ],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || "ASC",
        period: this.$route.query.period || [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD"),
        ],
      },
      ReportRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.period = [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ];
      this.search();
    },
    getData() {
      this.loading = true;

      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      ReportRepository.index({
        ...this.query,
        dateFrom: this.query.period[0] || null,
        dateTo: this.query.period[1] || null,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "active-outlet-report";
    const route = "active-outlet-report";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
