export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'region',
    label: 'field.region',
  },
  {
    key: 'agent',
    label: 'field.agentCode',
  },
  {
    key: 'seName',
    label: 'field.salesperson',
    subKey: 'seIdCard',
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    subKey: 'outletCode',
  },
  {
    key: 'outletSegmentName',
    label: 'field.outletSegment',
    localization: true,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
  },
  {
    key: 'longitude',
    label: 'field.longitude',
  },
];
